import { ref, reactive, computed, onMounted } from 'vue';
import * as API from '@/API/checking/spotChecking';
import {
    SearchParams,
    DealerTableData,
    AFCandLCTableData,
} from '@/views/Checking/spotChecking/spotCheckingVonder/pendingChecking/types';
import moment from 'moment';
import useThousands from '@/utils/payment/useThousands';
import downloadFile from '@/utils/payment/downloadFile';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Modal } from 'ant-design-vue';

const usePendingChecking = () => {
    const { commit } = useStore();
    const router = useRouter();

    const startDate = moment()
        .month(moment().month())
        .startOf('month')
        .format('YYYY-MM-DD');
    const endDate = moment()
        .month(moment().month())
        .endOf('month')
        .format('YYYY-MM-DD');

    const searchParams = reactive<SearchParams>({
        entity: undefined,
        bu: undefined,
        submissionPeriod: [startDate, endDate],
        priority: undefined,
    });

    const dealerColData = computed(() => {
        return [
            {
                title: '',
                dataIndex: 'light',
                slots: { customRender: 'light' },
                width: 20,
            },
            {
                title: 'Package Code',
                dataIndex: 'packageCode',
                slots: { customRender: 'packageCode' },
                width: 300,
            },
            {
                title: 'Time Line From',
                dataIndex: 'timeLineFrom',
                slots: { customRender: 'timeLineFrom' },
                width: 180,
            },
            {
                title: 'To',
                dataIndex: 'timeLineTo',
                slots: { customRender: 'timeLineTo' },
                width: 100,
            },
            {
                title: 'Program No.',
                dataIndex: 'programNo',
                customRender: useThousands(),
                align: 'right',
                width: 120,
            },
            {
                title: 'Confirmed No.',
                dataIndex: 'confirmedProgramNo',
                customRender: useThousands(),
                align: 'right',
                width: 120,
            },
            {
                title: 'Assignee',
                dataIndex: 'assignees',
                slots: { customRender: 'assignees' },
                width: 150,
            },
            { title: 'Status', dataIndex: 'status', width: 160 },
            {
                title: 'Last Update Time',
                dataIndex: 'lastUpdateTime',
                slots: { customRender: 'lastUpdateTime' },
                width: 120,
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                slots: { customRender: 'operation' },
                align: 'center',
                width: 200,
            },
        ];
    });
    const afcAndLcColData = computed(() => {
        return [
            { title: "", dataIndex: "light", slots: { customRender: 'light' }, width: 20 },
            { title: "Payout Round", dataIndex: "payoutRound", width: 200 },
            { title: "Time Line From", dataIndex: "timeLineFrom", slots: { customRender: 'timeLineFrom' }, width: 160 },
            { title: "To", dataIndex: "timeLineTo", slots: { customRender: 'timeLineTo' }, width: 100 },
            { title: "Program No.", dataIndex: "programNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Confirmed No.", dataIndex: "confirmedProgramNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Assignee", dataIndex: "assignees", slots: { customRender: 'assignees' }, width: 150 ,align:'center'},
            { title: "Status", dataIndex: "status", width: 200 },
            { title: "Last Update Time", dataIndex: "lastUpdateTime", slots: { customRender: 'lastUpdateTime' }, width: 150 },
            { title: "Operation", dataIndex: "operation", slots: { customRender: 'operation' }, align: 'center', width: 250 }
        ]
    })
    const dealerTblData = ref<DealerTableData[]>()
    const afcAndLcTblData = ref<AFCandLCTableData[]>()
    const getTableData = (type: string) => {
        const params = {
            entities: searchParams.entity,
            bus: searchParams.bu,
            submissionPeriodStart:
                searchParams.submissionPeriod[0] + ' 00:00:00',
            submissionPeriodEnd: searchParams.submissionPeriod[1] + ' 23:59:59',
            priority: searchParams.priority ? searchParams.priority : '',
        };
        if (type === 'dealer') {
            API.getPendingCheckingDealerList(params).then((data: any) => {
                dealerTblData.value = data;
            });
        } else {
            params.bus = undefined;
            params.entities = undefined;
            API.getPendingCheckingFinanceList(params).then((data: any) => {
                afcAndLcTblData.value = data;
            });
        }
    };
    const doSearch = () => {
        dealerTblData.value = [];
        afcAndLcTblData.value = [];
        getTableData('dealer');
        getTableData('afcAndLc');
    };
    const doReset = () => {
        searchParams.entity = undefined;
        searchParams.bu = undefined;
        searchParams.submissionPeriod = [startDate, endDate];
        searchParams.priority = undefined;
        doSearch();
    };

    //#region dealer confirm
    const confirmVisible = ref(false);
    const packageCodeId = ref('');
    const openConfirm = (record: DealerTableData) => {
        packageCodeId.value = record.packageCodeId;
        confirmVisible.value = true;
    };
    const confirmSuccess = () => {
        getTableData('dealer');
    };
    //#endregion

    //#region report dealer
    const dealerReportClick = (record: DealerTableData) => {
        const params = {
            url: `/claimapi/pendingChecking/reportDealer/${record.packageCodeId}`,
            method: 'post',
        };
        downloadFile(params).catch((msg) => message.warning(msg));
    };
    //#endregion

    //#region report 金融
    const financeReportClick = (record: AFCandLCTableData) => {
        const params = {
            url: `/claimapi/pendingChecking/reportFinance/${record.payoutRoundId}`,
            method: 'post',
        };
        downloadFile(params).catch((msg) => message.warning(msg));
    };
    //#endregion

    //#region 金融confirm
    const confirmClick = (record: AFCandLCTableData) => {
        Modal.confirm({
            title: 'Tips',
            content: 'Are you sure confirm?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk() {
                API.confirmPayoutRound(record.payoutRoundId).then((res) => {
                    if (res.code === '0') {
                        message.success('Successfully Confirm!');
                        getTableData('afcAndLc');
                    } else {
                        message.warning(res.message);
                    }
                });
            },
        });
    };
    //#endregion

    //#region download dealer
    const { state } = useStore();
    const dealerDownload = (record: DealerTableData) => {
        const params = {
            url: `/claimapi/pendingChecking/download/downloadDealer/${record.packageCodeId}?programBuId=${state.user.bu.orgid}`,
            method: 'get',
        };
        downloadFile(params).catch((msg) => message.warning(msg));
    };
    //#endregion

    //#region upload
    const uploadDealerVisible = ref(false);
    const type = ref('');
    const packageInfo = ref<DealerTableData>();
    const payoutInfo = ref<AFCandLCTableData>();
    const openDealerUpload = (record: DealerTableData) => {
        uploadDealerVisible.value = true;
        type.value = 'dealer';
        packageInfo.value = record;
    };
    const openFinanceUpload = (record: AFCandLCTableData) => {
        uploadDealerVisible.value = true;
        type.value = record.type;
        payoutInfo.value = record;
    };
    const closeUpload = (param: { isUploadVisible: boolean }) => {
        uploadDealerVisible.value = param.isUploadVisible;
    };
    //#endregion

    //#region dealer点击packageCode
    const packageCodeClick = (record: DealerTableData) => {
        const info = {
            id: record.packageCodeId,
            packageCode: record.packageCode,
        };
        commit('spotChecking/updatePackageData', info);
        router.push({
            path: '/spotChecking/packageDetail',
        });
    };
    //#endregion

    const logVisible = ref(false);
    const logData = ref();
    //#region dealer log
    const dealerLogClick = (record: DealerTableData) => {
        API.pendingCheckingDealerLog(record.packageCodeId).then((data) => {
            logData.value = data;
            logVisible.value = true;
        });
    };
    //#endregion

    //#region 金融下载
    const financeDownload = (record: AFCandLCTableData) => {
        const params = {
            url: `/claimapi/pendingChecking/download/download${record.type}/${record.payoutRoundId}`,
            method: 'get',
        };
        downloadFile(params).catch((msg) => message.warning(msg));
    };
    //#endregion

    //#region 金融log
    const financeLogClick = (record: AFCandLCTableData) => {
        API.pendingCheckingFinanceLog(record.payoutRoundId).then((data) => {
            logData.value = data;
            logVisible.value = true;
        });
    };
    //#endregion

    onMounted(() => {
        doSearch();
    });
    return {
        searchParams,
        dealerColData,
        afcAndLcColData,
        dealerTblData,
        afcAndLcTblData,
        doSearch,
        doReset,
        confirmVisible,
        packageCodeId,
        openConfirm,
        confirmSuccess,
        dealerReportClick,
        financeReportClick,
        confirmClick,
        dealerDownload,
        uploadDealerVisible,
        type,
        packageInfo,
        payoutInfo,
        openDealerUpload,
        openFinanceUpload,
        closeUpload,
        packageCodeClick,
        dealerLogClick,
        logVisible,
        logData,
        financeDownload,
        financeLogClick,
    };
};
export default usePendingChecking;
