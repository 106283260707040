
import { defineComponent, ref } from 'vue'
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';
import * as API from "@/API/checking/spotChecking";
import { FileInfo } from '@/API/types';
import { useStore } from 'vuex'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  emits: ["close-upload-visible", 'upload-success'],
  components: {
    ExclamationCircleOutlined
  },
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: true,
      default: ''
    },
    packageInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          packageCode: '',
          packageCodeId: ''
        }
      }
    },
    payoutInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          payoutRound: '',
          payoutRoundId: ''
        }
      }
    }
  },
  setup(props, context) {
    const { state } = useStore()

    //#region 上传
    // 文件上传
    const uploading = ref<boolean>(false);
    const isLt5M = ref<boolean[]>([]);
    const close = () => {
      context.emit('close-upload-visible', { isUploadVisible: false })
    }
    
    // 错误下载
    const downloadModalVisible = ref<boolean>(false)
    const downloadTipsText = ref<string>('')
    const loading = ref<boolean>(false)
    let errorFileList: string[] = []

    const fileList = ref<FileItem[]>([]);
    // 上传前
    const beforeUpload = (file: FileItem) => {
      console.log('进入上传前方法中。。。。。。。')
      isLt5M.value.push(file.size / 1024 / 1024 < state.payment.maxFileSize)
      return false
    }

    const fileChange = (info: FileInfo) => {
      const lastFile: FileItem = info.fileList[info.fileList.length - 1]
      if (lastFile && lastFile.type === 'text/html') {
        message.error('Attachment can not be an HTML file.')
        fileList.value.splice(info.fileList.length - 1, 1)
        isLt5M.value.splice(info.fileList.length - 1, 1)
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
      isLt5M.value = []
      fileList.value.forEach((item: FileItem)=>{
        isLt5M.value.push(item.size / 1024 / 1024 < state.payment.maxFileSize)
      })
    };
    const rtUpload = () => {
      const formData = new FormData();
      fileList.value.forEach((file: FileItem) => {
        formData.append('document', file.originFileObj);
      });
      uploading.value = true;
      API.uploadPendingCheckingRt({ payoutRoundId: props.payoutInfo.payoutRoundId }, formData).then(res => {
        fileList.value = [];
        uploading.value = false;
        if (res.code !== '0') {
          if (res.code === '-2' && res.data && res.data.length > 0) {
            errorFileList = res.data
            downloadTipsText.value = res.message
            downloadModalVisible.value = true
          } else {
            message.warning(res.message)
          }
        } else {
          message.success('Successfully Upload!')
          close()
          context.emit('upload-success')
        }
      }).catch(() => {
        fileList.value = [];
        uploading.value = false;
      })
    }
    const wsUpload = () => {
      const formData = new FormData();
      fileList.value.forEach((file: FileItem) => {
        formData.append('document', file.originFileObj);
      });
      uploading.value = true;
      API.uploadPendingCheckingWs({ payoutRoundId: props.payoutInfo.payoutRoundId }, formData).then(res => {
        fileList.value = [];
        uploading.value = false;
        if (res.code !== '0') {
          if (res.code === '-2' && res.data && res.data.length > 0) {
            errorFileList = res.data
            downloadTipsText.value = res.message
            downloadModalVisible.value = true
          } else {
            message.warning(res.message)
          }
        } else {
          message.success('Successfully Upload!')
          close()
          context.emit('upload-success')
        }
      }).catch(() => {
        fileList.value = [];
        uploading.value = false;
      })
    }
    const dealerUpload = () => {
      const formData = new FormData();
      fileList.value.forEach((file: FileItem) => {
        formData.append('document', file.originFileObj);
      });
      uploading.value = true;
      API.uploadPendingCheckingDealer({ packageCodeId: props.packageInfo.packageCodeId }, formData).then(res => {
        fileList.value = [];
        uploading.value = false;
        if (res.code !== '0') {
          if (res.code === '-2' && res.data && res.data.length > 0) {
            errorFileList = res.data
            downloadTipsText.value = res.message
            downloadModalVisible.value = true
          } else {
            message.warning(res.message)
          }
        } else {
          message.success('Successfully Upload!')
          close()
          context.emit('upload-success')
        }
      }).catch(() => {
        fileList.value = [];
        uploading.value = false;
      })
    }
    const submitFile = () => {
      if (fileList.value.length<=0){
        message.warning('you have not uploaded any attachments');
        return
      }
      if (fileList.value.length>10){
        message.warning('Attachment batch upload can not more than 10.');
        return
      }
      // const isMore5m = isLt5M.value.every(( item )=>{
      //     return item;
      // })
      // if (!isMore5m){
      //   message.warning(`Attachment can not large than ${state.payment.maxFileSize}M.`);
      //   return
      // }
      if (props.type === 'WS') {
        wsUpload()
      } else if (props.type === 'RT') {
        rtUpload()
      } else {
        dealerUpload()
      }
    }
    //#endregion

    //#region 错误下载
    // 上传功能出错后下载模态窗口
    const handleDownload = () =>{
      loading.value = true
      // 调下载错误文件接口
      API.downloadFiles(errorFileList).then(downloadRes => {
        if (downloadRes.code !== '0') {
          message.warning(downloadRes.message)
          loading.value = false
        } else {
          const downloadElement = document.createElement('a');
          downloadElement.href = downloadRes.data;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          loading.value = false
        }
      }).catch(() => {
        loading.value = false
      })
    }
    const handleCancel = () => {
      downloadModalVisible.value = false;
    };
    //#endregion

    return {
      fileList,
      close,
      submitFile,
      beforeUpload,
      handleRemove,
      fileChange,
      uploading,
      downloadModalVisible,
      downloadTipsText,
      loading,
      handleDownload,
      handleCancel
    }
  }
})
