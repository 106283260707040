
import { defineComponent, ref } from "vue";
import usePendingChecking from "@/hooks/checking/spotChecking/usePendingChecking";
import { elementSize } from "@/utils";
import ConfirmModal from "./confirmModal.vue";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
// import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import AllEntitySelector from "@/views/Payment/components/AllEntitySelector.vue";
import UploadDetail from "./uploadDetail.vue";
import LogModal from "./logModal.vue";
import { FileSearchOutlined } from "@ant-design/icons-vue";
import GetColumnWidth from '@/utils/payment/getColumnWidth'

export default defineComponent({
  name: "paymentApproveMgmt",
  components: {
    ConfirmModal,
    BuSelector,
    // EntitySelector,
    AllEntitySelector,
    UploadDetail,
    LogModal,
    FileSearchOutlined,
  },
  props: {},
  setup() {
    //#region [引用useApprovalMain.ts相关]
    const {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      confirmVisible,
      packageCodeId,
      openConfirm,
      confirmSuccess,
      dealerReportClick,
      financeReportClick,
      confirmClick,
      dealerDownload,
      uploadDealerVisible,
      type,
      packageInfo,
      payoutInfo,
      openDealerUpload,
      openFinanceUpload,
      closeUpload,
      packageCodeClick,
      dealerLogClick,
      logVisible,
      logData,
      financeDownload,
      financeLogClick,
    } = usePendingChecking();

    const tableSize = elementSize(".ant-table-wrapper");
    const dealertTableWidth = ref(GetColumnWidth(dealerColData));
    const flTableWidth = ref(GetColumnWidth(afcAndLcColData));
    return {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      confirmVisible,
      packageCodeId,
      tableSize,
      openConfirm,
      confirmSuccess,
      dealerReportClick,
      financeReportClick,
      confirmClick,
      dealerDownload,
      uploadDealerVisible,
      type,
      packageInfo,
      payoutInfo,
      openDealerUpload,
      openFinanceUpload,
      closeUpload,
      packageCodeClick,
      dealerLogClick,
      logVisible,
      logData,
      financeDownload,
      financeLogClick,
      dealertTableWidth,
      flTableWidth
    };
  },
});
